import React, { useEffect } from "react"
import { navigate } from "gatsby"

import { Logo } from "../components/svg/logo"
import Seo from "../components/seo"
import { gsap } from "gsap"

import {
  isBrowser,
} from "react-device-detect"


const NotFoundPage = () => {

  useEffect(() => {

    if(isBrowser) {
      const button = document.querySelector("#cursor");
      document.addEventListener("mousemove", function(ev) {
        gsap.to(button, 0, {
          css: {
            transform: `translate(-50%, -50%)`,
            left: ev.clientX,
            top: ev.clientY
          }
        })
      })

      document.addEventListener("mousedown", () => {
        gsap.to(button, 0.1, {
          css: { transform: `translate(-50%, -50%) scale(0.9)` }
        })
      })

      document.addEventListener("mouseup", () => {
        gsap.to(button, 0.1, {
          css: { transform: `translate(-50%, -50%) scale(1)` }
        })
      })
    }

  }, [])

  return (
    <div role={"button"} tabIndex={0} className={"page-404"} onClick={() => navigate("/")} onKeyDown={() => navigate("/")}>
      <Seo title="404: Not Found"  slug="404" />
      <div className={"logo"}>
        <Logo />
      </div>
      <div className={"container"}>
        <h1 style={{opacity: 1}}>PAGE DOES NOT EXIST</h1>
        <div id="cursor">Go Home</div>
      </div>
    </div>
  )
}

export default NotFoundPage
